<template>
  <v-container fluid>
    <v-card flat>
      <v-data-table
        :items="acl"
        :headers="[
          { text: 'Nachname', value: 'familyName' },
          { text: 'Vorname', value: 'givenName' },
          { text: 'Löschen', value: 'del' }
        ]"
        sort-by="person.familyName"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item.del="{item}">
          <v-btn text fab small @click="del(item._id)"><v-icon>far fa-trash-alt</v-icon></v-btn>
        </template>
      </v-data-table>
    </v-card>
    <base-edit-dialog-personsuche
      :id="id"
      label="Neuen Wettkampfverantwortlichen hinzufügen"
      @input="add"
    />
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'

export default {
  name: 'wkacl',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    }
  },

  computed: {
    acl () {
      return this.e?.acl || []
    }
  },

  methods: {
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $aclid: UUID!) {
            PersonUpdateAclDelete(id: $id, type: stbltftc, aclid: $aclid) { _id familyName givenName }
          }
        `,
        variables: {
          id: id,
          aclid: this.id
        }
      })
    },
    add ({ value }) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $aclid: UUID!) {
            PersonUpdateAclAdd(id: $id, type: stbltftc, aclid: $aclid) { _id familyName givenName }
          }
        `,
        variables: {
          id: value,
          aclid: this.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
